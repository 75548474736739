import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FontawesomeModule } from '../shared/fontawesome.module';
import { MaterialModule } from '../shared/material.module';

import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NavigationComponent, FooterComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontawesomeModule,
    MaterialModule,
    RouterModule,
  ],
  exports: [NavigationComponent, FooterComponent],
})
export class CoreModule {}
