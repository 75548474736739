import { Component, OnInit } from '@angular/core';
import { NavigationLink, navigationLinks } from './navigation-links';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  navLinks: NavigationLink[] = navigationLinks;

  constructor() {}

  ngOnInit(): void {}
}
