import { Injectable } from '@angular/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  private busyCount = 0;

  constructor(private spinnerSvc: NgxSpinnerService) {}

  busy() {
    this.busyCount++;
    this.spinnerSvc.show(undefined, {
      bdColor: 'rgba(0, 0, 0, 0.8)',
      fullScreen: true,
      color: 'rgba(255, 255, 255, 0.8)',
      template: null,
      size: 'small'
    });
  }

  idle() {
    this.busyCount--;
    if (this.busyCount <= 0) {
      this.busyCount = 0;
      this.spinnerSvc.hide();
    }
  }
}
