import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class FontawesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebookSquare, faFilePdf, faInstagram, faEnvelope, faSpinner);
  }
}
