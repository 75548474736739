export class NavigationLink {
  constructor(
    public title: string,
    public url: string,
    public options: {} = {}
  ) {}
}

export const navigationLinks: NavigationLink[] = [
  new NavigationLink('Collies', '/', { exact: true }),
  new NavigationLink('Play', '/play'),
  new NavigationLink('Sponsors', '/sponsors'),
  new NavigationLink('Committee', '/committee'),
  new NavigationLink('Coaching', '/coaching'),
  new NavigationLink('Calendar', '/calendar'),
  new NavigationLink('Draws', '/draws'),
  new NavigationLink('Fields', '/fields'),
  new NavigationLink('Contact Us', '/contact'),
];
