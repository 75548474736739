<mat-sidenav-container class="main-container">
  <mat-sidenav position="end" #sidenav role="navigation">
    <mat-nav-list>
      <ng-container *ngFor="let link of navLinks; let i = first">
        <a mat-list-item routerLink="{{ link.url }}" routerLinkActive="active" [routerLinkActiveOptions]="link.options"
          (click)="sidenav.close()">{{ link.title }}</a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="#1f2037" fxLayout="row" fxLayoutAlign="space-between center" style="height: 96px">
      <div class="nav-list" fxFlex fxLayout fxLayoutAlign="center" fxHide.lt-lg>
        <ul fxLayout fxLayoutGap="10px" class="navigation-items">
          <li *ngFor="let link of navLinks; let i = first">
            <a routerLink="{{ link.url }}" routerLinkActive="active"
              [routerLinkActiveOptions]="link.options">{{ link.title }}</a>
          </li>

        </ul>
      </div>
      <div fxHide.gt-md>
        <button mat-icon-button (click)="sidenav.toggle()" class="nav-toggle">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

    </mat-toolbar>

    <main class="main">
      <ng-content></ng-content>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
