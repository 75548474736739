import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'play',
    loadChildren: () =>
      import('./pages/play-page/play-page.module').then(
        (m) => m.PlayPageModule
      ),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./pages/calendar-page/calendar-page.module').then(
        (m) => m.CalendarPageModule
      ),
  },
  {
    path: 'sponsors',
    loadChildren: () =>
      import('./pages/sponsors-page/sponsors-page.module').then(
        (m) => m.SponsorsPageModule
      ),
  },
  {
    path: 'draws',
    loadChildren: () =>
      import('./pages/draws-page/draws-page.module').then(
        (m) => m.DrawsPageModule
      ),
  },
  {
    path: 'committee',
    loadChildren: () =>
      import('./pages/committee-page/committee-page.module').then(
        (m) => m.CommitteePageModule
      ),
  },
  {
    path: 'coaching',
    loadChildren: () =>
      import('./pages/coaching-page/coaching-page.module').then(
        (m) => m.CoachingPageModule
      ),
  },
  {
    path: 'fields',
    loadChildren: () =>
      import('./pages/maps-page/maps-page.module').then(
        (m) => m.MapsPageModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact-page/contact-page.module').then(
        (m) => m.ContactPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];
