<div class="footer-links">
  <div class="social">
    <a fxFlexAlign="center" class="fb" mat-button color="primary" href="https://www.facebook.com/CollegiansSC/"
      target="_blank">
      <fa-icon [icon]="['fab', 'facebook-square']" size="3x" [fixedWidth]="true"></fa-icon> Like us on Facebook
    </a>
    <a fxFlexAlign="center" class="fb" mat-button color="primary" href="https://www.instagram.com/collegianssc/"
      target="_blank">
      <fa-icon [icon]="['fab', 'instagram']" size="3x" [fixedWidth]="true"></fa-icon> Follow us on Instagram
    </a>
    <a fxFlexAlign="center" class="fb" mat-button color="primary" href="https://collegianssc.teamapp.com/"
      target="_blank">
      <img class="team-app" src="/assets/images/logos/team-app_logo.png" alt="TeamApp Logo"> Download our TeamApp
    </a>
  </div>
  <div>
    <a fxFlexAlign="center" class="fb" mat-button color="primary" href="https://mailchi.mp/9bdddc636bfd/collegianssc"
      target="_blank">
      <fa-icon [icon]="['fas', 'envelope']" size="3x" [fixedWidth]="true"></fa-icon> Subscribe to our E-Newsletter
    </a>
  </div>
  <a fxFlexAlign="center" mat-button color="primary" href="/assets/policies/privacy_policy.pdf" target="_blank">Privacy
    Policy</a>
  <a fxFlexAlign="center" mat-button color="primary" routerLink="/contact">Contact Us</a>

</div>
<footer fxLayout="column" fxLayoutAlign="center center">
  <p>&copy; Copyright 2021 Collegians Soccer Club <span>Proudly designed and hosted by Paul Denmead Web Designs</span>
  </p>
</footer>
